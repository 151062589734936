<template>
    <router-view />
</template>
<script></script>
<style lang="scss">
html,
body {
    margin: 0;
    padding: 0;
    font-family: size;
}

@font-face {
    font-family: size;
    src: url(./assets/size/MFWENYAN_NONCOMMERCIAL-REGULAR\(2\).OTF);
}
</style>
