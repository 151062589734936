import { createRouter, createWebHashHistory } from "vue-router";
import loginView from "../views/login/loginView.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: loginView,
  },
  {
    path: "/face/index",
    name: "face",
    component: () => import("../views/face/index.vue"),
  },
  {
    path: "/notice/index",
    name: "notice",
    component: () => import("../views/notice/index.vue"),
  },
  {
    path: "/examination/index",
    name: "examination",
    component: () => import("../views/examination/index.vue"),
  },
];
const router = createRouter({
  mode: "hash",
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
