<template>
  <div class="home" style="position: relative;">
    <div class="title">建设领域施工现场专业人员培训测试系统</div>
    <img src="../../assets/bgimg.jpg" style="position: absolute;left: 10%;top: 80%;z-index: 0;" />
    <div style="width: 435px;z-index: 1;" class="from">
      <t-form ref="form" :data="formData" :colon="true" :label-width="100" @reset="onReset" @submit="onSubmit"
        :rules="FORM_RULES">
        <t-form-item label="身份证号" initial-data="TDesign" name="IdNmber" style="font-size: 18px">
          <t-input clearable placeholder="请输入身份证号" v-model="formData.IdNmber" style="width: 600px">
            <template #prefix-icon>
              <img src="../../assets/sfz.png" width="16px" height="15px" />
            </template>
          </t-input>
        </t-form-item>

        <t-form-item>
          <t-button theme="primary" type="submit" block>登录</t-button>
        </t-form-item>
      </t-form>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { MessagePlugin } from "tdesign-vue-next";
import router from "@/router";
import { getLogin } from "@/api/table";

const formData = reactive({});
const FORM_RULES = {
  IdNmber: [
    { min: 18, message: "身份证号格式不正确", type: "error", trigger: "blur" },
    { max: 18, message: "身份证号格式不正确", type: "error", trigger: "blur" },
  ],
};

const onReset = () => {
  MessagePlugin.success("重置成功");
};
//
const onSubmit = ({ validateResult, firstError }) => {
  if (validateResult === true) {
    const url = "https://zy.zhenghuimachinery.com/index/login";
    const data = { card: formData.IdNmber };
    getLogin(url, data)
      .then((res) => {
        if (res.code != 0) {
          MessagePlugin.error(res.data);
          return;
        }
        MessagePlugin.success("登陆成功");
        let siaDataName = res.data.name;
        let siaDataCard = res.data.card;
        let siaDataSrc = res.data.image;
        let siaDataJob = res.data.job;
        let siaDataScore = res.data.score;
        sessionStorage.setItem("job", siaDataJob);
        sessionStorage.setItem("score", siaDataScore);
        sessionStorage.setItem("name", siaDataName);
        sessionStorage.setItem("card", siaDataCard);
        sessionStorage.setItem("src", siaDataSrc);
        console.log(res.data);
        router.push({
          path: "/face/index",
        });
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  } else {
    console.log("Validate Errors: ", firstError, validateResult);
    MessagePlugin.warning(firstError);
  }
};
</script>

<style scoped>
.home {
  background-image: url(../../assets/bj.jpg);
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
}

.title {
  padding-bottom: 150px;
  font-size: 60px;
  color: #fff;
  margin-top: 160px;
}

.from {
  margin-top: 150px;
}
</style>
