import axios from "axios";
// 获取题目
export function getTest(url, data) {
  return axios
    .post(url, data)
    .then((response) => {
      // 处理成功的响应数据
      return response.data;
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      throw error;
    });
}
// 登录
export function getLogin(url, data) {
  return axios
    .post(url, data)
    .then((response) => {
      // 处理成功的响应数据
      return response.data;
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      throw error;
    });
}

// 提交
export function postSubmit(url, data) {
  return axios
    .post(url, data)
    .then((response) => {
      // 处理成功的响应数据
      return response.data;
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      throw error;
    });
}
// 获取图片
export function request(url, data, option) {
  return axios
    .post(url, data, option)
    .then((response) => {
      // 处理成功的响应数据
      return response.data;
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      throw error;
    });
}
// 图片上传
export function uploads(data) {
  return request("https://zy.zhenghuimachinery.com/upload/uploads", data, {
    headers: { "content-type": "multipart/from-data" },
  });
}
//提交图片
export function image(url, data) {
  return axios
    .post(url, data)
    .then((response) => {
      // 处理成功的响应数据
      return response.data;
    })
    .catch((error) => {
      // 处理请求错误
      console.error(error);
      throw error;
    });
}
